.custom-navbar {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
}

.custom-navbar .navbar-collapse {
    transition: transform 0.3s ease-in-out;
}

.custom-navbar .navbar-toggler {
    z-index: 999999999;
}

.custom-navbar .navbar-collapse {
    transition: transform 0.3s ease-in-out;
}

.custom-navbar .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0c2739;
    transform: translateX(-100%);
    z-index: 99999999;
}

.custom-navbar .navbar-collapse.show {
    transform: translateX(0);
}

.custom-navbar .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 992px) {
    .custom-navbar #butt_comunicacion_mob {
        background-image: url(../assets/images/butt_comunicacion_mobile.png);
        background-repeat: no-repeat;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-size: cover;
        background-position-y: 50%;
        opacity: 1 !important;
    }

    .custom-navbar #butt_seguridad_mob {
        background-image: url(../assets/images/butt_seguridad_mobile.png);
        background-repeat: no-repeat;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-size: cover;
        background-position-y: 50%;
        opacity: 1 !important;
    }

    .custom-navbar #butt_cloud_mob {
        background-image: url(../assets/images/butt_cloud_mobile.png);
        background-repeat: no-repeat;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-size: cover;
        background-position-y: 50%;
        opacity: 1 !important;
    }

    .custom-navbar #butt_iot_mob {
        background-image: url(../assets/images/butt_iot_mobile.png);
        background-repeat: no-repeat;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-size: cover;
        background-position-y: 50%;
        opacity: 1 !important;
    }

    .custom-navbar #butt_contacto_mob {
        background-image: url(../assets/images/butt_iot_mobile.png);
        background-repeat: no-repeat;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        background-size: cover;
        background-position-y: 50%;
        opacity: 1 !important;
    }

    .custom-navbar #butt_comunicacion_mob img,
    .custom-navbar #butt_seguridad_mob img,
    .custom-navbar #butt_cloud_mob img,
    .custom-navbar #butt_iot_mob img, .custom-navbar #butt_contacto_mob img  {
        width: 120px;
        height: 120px;
    }

    .custom-navbar a.navbar-brand {
        z-index: 99;
    }

    .custom-navbar .navbar-toggler-icon {
        background-image: none !important;
        width: 30px;
        height: 3px;
        background-color: #ffffff;
        position: relative;
    }

    .custom-navbar .navbar-toggler-icon::before,
    .custom-navbar .navbar-toggler-icon::after {
        content: '';
        position: absolute;
        width: 45px;
        height: 4px;
        border-radius: 3px;
        background-color: #ffffff;
        left: 0;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    .custom-navbar .navbar-toggler-icon::before {
        top: -14px;
    }

    .custom-navbar .navbar-toggler-icon::after {
        top: 14px;
    }

    .custom-navbar .navbar-toggler.is-open .navbar-toggler-icon {
        background-color: initial!important;
    }

    .custom-navbar .navbar-toggler.is-open {
        background-color: inherit;
    }

    .custom-navbar .navbar-toggler.is-open .navbar-toggler-icon::before {
        transform: translateY(14px) rotate(45deg);
    }

    .custom-navbar .navbar-toggler.is-open .navbar-toggler-icon::after {
        transform: translateY(-14px) rotate(-45deg);
    }

    .custom-navbar .navbar-toggler.is-open .navbar-toggler-icon::before,
    .custom-navbar .navbar-toggler.is-open .navbar-toggler-icon::after {
        width: 45px;
    }

    .custom-navbar .ms-auto.navbar-nav a {
        border-radius: 5px;
        margin: 5px 0;
        background-color: #0c2739;
        transition: all 0.5s ease-in;
    }

    .custom-navbar .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0c2739;
        transform: translateX(-100%);
        z-index: 99999999;
    }

    .custom-navbar .navbar-collapse {
        display: flex;
        align-items: center;
    }

    .custom-navbar .navbar-collapse.show {
        transform: translateX(0);
        display: flex;
        align-items: center;
    }

    .custom-navbar .navbar-toggler {
        position: relative;
        z-index: 999999999;
        border: none;
        border-radius: 4px;
        background-color: #cacaca00;
        width: 60px;
    }

    .custom-navbar a.navbar-brand {
        z-index: 99;
    }

    body .custom-navbar .navbar a {
        z-index: 9999;
    }

    .custom-navbar .navbar-toggler-icon {
        width: 30px;
        height: 4px !important;
        border-radius: 3px;
        display: flex;
    }

    .custom-navbar button:focus:not(:focus-visible) {
        box-shadow: none;
    }
}
