/*--- Body ---*/
body{
  background-color: #00283b;
  margin: 0px;
  color: #ffffff;
  overflow-x: hidden; /* Deshabilita el scroll horizontal */
}

/*--- select color---*/
select.form-control option {
  color: black; 
}


/* CustomCursor.css */
* {
  cursor: none;
}

.cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  pointer-events: none;
  z-index: 999999999999999;
  transition: transform 0.2s, opacity 0.2s;
}

.cursor.opacity-low {
  background-color: #ffffff7a;
}



.btn{
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  float: right;
}




/*--- Estilos contenedor ---*/
.container{
  width: 70%;
  margin: 0 15%;
}



/*--- Tipografias ---*/
h1 {
  font-family: 'Telefonica ExtraLight';
  font-size: 3.5em; 
}

h1 b{
  font-family: 'Telefonica ExtraLight';
  color: #4ee9fe;
}

h1.blue {
  font-family: 'Telefonica ExtraLight';
  font-size: 3.5em; 
  color: #3c9ef4;
}

h2 {
  font-family: 'Telefonica Regular';
  font-size: 2em; 
}

h3 {
  font-family: 'Telefonica Light';
  font-size: 1.75em; 
  text-align: center;
}

h4 {
  font-family: 'Telefonica ExtraLight';
  font-size: 1.5em; 
}

p, label {
  font-family: 'Myriad Pro Regular';
  font-size: 1em;
}

span {
  font-family: 'Telefonica Regular Italic';
}

a {
  font-family: 'Telefonica Regular';
  text-decoration: none; 
  color: #ffffff; 
}

a:hover, a:focus, .nav-list a:hover {
  text-decoration: none; 
  color: #ffffff; 
}




/*--- Subheader ---*/
.subheader{
  margin-bottom: -5%;
}

.subheader h1{
  font-size: 7em;
  line-height: 1em;
  margin: 0% 0% 4% 0%;
}

.contenedortextsubheader{
  width: 25vw;
}

.no-video .contenedortextsubheader{
  width: 100%;
}

.contenedortextsubheader p{
  font-size: 1.3em;
}







/*--- Efectos background ---*/
.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.ball {
  position: absolute;
  width: 600px;
  height: 600px;
  background-color: #4ee9fe5f;
  border-radius: 50%;
  filter: blur(50px); 
  opacity: 0.4;
}




/*--- Header Effect ---*/
.header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 10;
  height: 10vh;
}

.logo-container{
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999999999;
}

.nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-list li {
  font-size: 18px;
}

.nav-list a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-list a:hover {
  color: #4ee9fe;
}

.logo-container .logo{
  width: 220px;
}



/*--- Home ---*/
.home {
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: space-around; 
  height: 80vh;
}

.home .homeleft {
  flex: 1 1;
  width: 100%;
  padding: 0 10%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.homeleft h1 {
  margin: 0px !important;
}

.home .homeright {
  flex: 1; 
  padding: 0px; 
}

.home .homeright img{
  max-width: 850px;
  float: right;
  position: fixed;
  right: 0px;
  bottom: 0px;
  mix-blend-mode: luminosity;
  opacity: 0.6;
  pointer-events: none;
}

.button-container {
  display: flex;
  gap: 40px;
  flex-direction: row;
  align-items: center;
} 

.nav-button {
  padding: 10px 20px;
  background-color: #3498db23;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.nav-button:hover {
  background-color: #297fb939;
}




/*--- Pre Header ---*/
.preheader{
  display: flex;
  margin: 5% 0;
  width: 100%;
  align-items: center;
}

.preheader img{
  max-width: 300px;
}

.preheader p{
  color: #4ee9fe;
  margin-left: auto;
  width: 50%;
}

.preheader p::after{
  content: ""; 
  display: block; 
  width: 50%; 
  margin: 20px auto;
  height: 1px; 
  background: #4ee9fe; 
  margin-bottom: 10px;
  opacity: 1;
  transition: all .8s ease;
  position: absolute;
  right: 0px;
}




/*--- Botonera Menu Home ---*/
#butt_comunicacion{
  background-image: url(./assets/images/butt_comunicacion_desktop.png);
  width: 100px;
  height: 100px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_seguridad{
  background-image: url(./assets/images/butt_seguridad_desktop.png);
  width: 100px;
  height: 100px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_cloud{
  background-image: url(./assets/images/butt_cloud_desktop.png);
  width: 100px;
  height: 100px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_iot{
  background-image: url(./assets/images/butt_iot_desktop.png);
  width: 100px;
  height: 100px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_contacto{
  background-image: url(./assets/images/butt_iot_desktop.png);
  width: 100px;
  height: 100px;
  background-size: contain;
  opacity: 0.5 !important;
  filter: grayscale(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

#butt_contacto_mob{
  filter: grayscale(100%);
}

#butt_comunicacion img, #butt_seguridad img, #butt_cloud img, #butt_iot img, #butt_contacto img{
  width: 100px;
  height: 100px;
  border-radius: 14px;
}

#butt_comunicacion, #butt_seguridad, #butt_cloud, #butt_iot, #butt_contacto, #butt_comunicacion_mob, #butt_seguridad_mob, #butt_cloud_mob, #butt_iot_mob, #butt_contacto_mob{
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

#butt_comunicacion:hover, #butt_seguridad:hover, #butt_cloud:hover, #butt_iot:hover, #butt_contacto:hover{
  opacity: 1!important;
  scale: 1.1;
}

#butt_comunicacion_mob:hover, #butt_seguridad_mob:hover, #butt_cloud_mob:hover, #butt_iot_mob:hover, #butt_contacto_mob:hover {
  opacity: 1!important;
}

#butt_comunicacion img:hover{
  box-shadow: 0px 0px 40px #4ee9fe;
}

#butt_seguridad img:hover{
  box-shadow: 0px 0px 40px #be23de;
}

#butt_cloud img:hover{
  box-shadow: 0px 0px 40px #3bde01;
}

#butt_iot img:hover{
  box-shadow: 0px 0px 40px #db299f;
}

#butt_contacto img:hover{
  box-shadow: 0px 0px 40px #ffffff;
}

#butt_comunicacion_mob img:hover{
  box-shadow: 0px 5px 20px #4ee9fe;
}

#butt_seguridad_mob img:hover{
  box-shadow: 0px 5px 20px #be23de;
}

#butt_cloud_mob img:hover{
  box-shadow: 0px 5px 20px #3bde01;
}

#butt_iot_mob img:hover{
  box-shadow: 0px 5px 20px #db299f;
}

#butt_contacto_mob img:hover{
  box-shadow: 0px 5px 20px #ffffff;
}






body.comunicacion #butt_comunicacion img{
  box-shadow: 0px 0px 40px #4ee9fe;
}

body.seguridad #butt_seguridad img{
  box-shadow: 0px 0px 40px #be23de;
}

body.cloud #butt_cloud img{
  box-shadow: 0px 0px 40px #3bde01;
}

body.iot #butt_iot img{
  box-shadow: 0px 0px 40px #db299f;
}

body.comunicacion #butt_comunicacion_mob img{
  box-shadow: 0px 5px 20px #4ee9fe;
}

body.seguridad #butt_seguridad_mob img{
  box-shadow: 0px 5px 20px #be23de;
}

body.cloud #butt_cloud_mob img{
  box-shadow: 0px 5px 20px #3bde01;
}

body.iot #butt_iot_mob img{
  box-shadow: 0px 5px 20px #db299f;
}

body.contacto #butt_contacto_mob img{
  box-shadow: 0px 5px 20px #ffffff;
}

body.comunicacion #butt_comunicacion_mob, body.seguridad #butt_seguridad_mob, body.cloud #butt_cloud_mob, body.iot #butt_iot_mob, body.contacto  #butt_contacto_mob{
  opacity: 1 !important;
}




/*--- Tamaño fuente contacto ---*/
body.contacto h2{
  font-size: 3em;
}

body.contacto p{
  font-size: 1.5em;
  font-weight: 100!important;
  margin: 0;
}






/*--- Botonera Menu Paginas ---*/
#butt_comunicacion_mob{
  background-image: url(./assets/images/butt_comunicacion_mobile.png);
  background-repeat: no-repeat;
  width: 100px;
  height: 53px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_seguridad_mob{
  background-image: url(./assets/images/butt_seguridad_mobile.png);
  background-repeat: no-repeat;
  width: 100px;
  height: 53px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_cloud_mob {
  background-image: url(./assets/images/butt_cloud_mobile.png);
  background-repeat: no-repeat;
  width: 100px;
  height: 53px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_iot_mob {
  background-image: url(./assets/images/butt_iot_mobile.png);
  background-repeat: no-repeat;
  width: 100px;
  height: 53px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_contacto_mob {
  background-image: url(./assets/images/butt_iot_mobile.png);
  background-repeat: no-repeat;
  width: 100px;
  height: 53px;
  background-size: contain;
  opacity: 0.5!important;
}

#butt_comunicacion_mob img, #butt_seguridad_mob img, #butt_cloud_mob img, #butt_iot_mob img, #butt_contacto_mob img{
  width: 100px;
  height: 53px;
  border-radius: 7px;
}



/* Contenedor */
.seguridad.container, .cloud.container, .comunicacion.container, .iot.container{
  height: 80vh;
  display: flex;
  align-items: center;
}

body.cloud .footer-container, body.comunicacion .footer-container, body.iot .footer-container, body.seguridad .footer-container, body.registros .footer-container, body.contacto .footer-container {
  display: none;
}

body.default .logoblancoempresas{
  display: none;
}

.login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login svg{
  color: #ffffff;
}


.login form{
  display: flex;
  align-items: center;
}




/* Seguridad */
.seguridad .seguridad-list button {
  background-color: #cacaca00;
  color: #fff;
  display: block;
  font-family: Telefonica ExtraLight;
  font-size: 3em;
  border: 1px solid;
  padding: 5px 30px;
  margin: 10px 0;
  border-radius: 20px;
}

.seguridad .seguridad-list button:hover {
  color: #a13da1;
  box-shadow: 0px 0px 50px #a13da1;
}

.seguridad-list{  
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}

.seguridad .animated-background .ball{
  background-color: #a13da1;
}

body.seguridad{
  background-color: #642a72;
}

.seguridad-list button {
  position: relative;
  transition: transform 0.3s ease, padding-left 0.3s ease;
  padding-left: 0;
}

.seguridad .seguridad-list button {
  display: flex;
  align-items: center;
}

.seguridad-list button::after {
  content: "";
  display: inline-block;
  background-image: url('./assets/images/icono_seguridad_span.png');
  background-repeat: no-repeat;
  background-size: 50px auto;
  width: 50px; 
  height: 50px; 
  font-family: 'Telefonica ExtraLight';
  font-weight: 100;
  position: absolute;
  left: -50px; 
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}

.seguridad-list button:hover {
  transform: translateX(20px);
  padding-left: 60px;
}

.seguridad-list button:hover::after {
  left: 10px; 
  opacity: 1;
}




/* Comunicacion */
.comunicacion .comunicacion-list button {
  background-color: #cacaca00;
  color: #fff;
  display: block;
  font-family: Telefonica ExtraLight;
  font-size: 3em;
  border: 1px solid;
  padding: 5px 30px;
  margin: 10px 0;
  border-radius: 20px;
}

.comunicacion .comunicacion-list button:hover {
  color: #4fe8fe;
  box-shadow: 0px 0px 50px #4fe8fe;
}

.comunicacion-list{  
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}

.comunicacion .animated-background .ball{
  background-color: #4fe8fe;
}

body.comunicacion{
  background-color: #0c2739;
}

body.comunicacion img{
  background-image: url("./assets/images/fondo_comunicacion.png");
  display: block;
  z-index: 9999999;
}

.comunicacion-list button {
  position: relative;
  transition: transform 0.3s ease, padding-left 0.3s ease;
  padding-left: 0;
}

.comunicacion .comunicacion-list button {
  display: flex;
  align-items: center;
}

.comunicacion-list button::after {
  content: "";
  display: inline-block;
  background-image: url('./assets/images/icono_comu_span.png');
  background-repeat: no-repeat;
  background-size: 50px auto;
  width: 50px; 
  height: 50px; 
  font-family: 'Telefonica ExtraLight';
  font-weight: 100;
  position: absolute;
  left: -50px; 
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}

.comunicacion-list button:hover {
  transform: translateX(20px);
  padding-left: 60px;
}

.comunicacion-list button:hover::after {
  left: 10px; 
  opacity: 1;
}




/* Cloud */
.cloud .cloud-list button {
  background-color: #cacaca00;
  color: #fff;
  display: block;
  font-family: Telefonica ExtraLight;
  font-size: 3em;
  border: 1px solid;
  padding: 0px 30px;
  margin: 10px 0;
  border-radius: 20px;
}

.cloud .cloud-list button:hover {
  color: #5cb615;
  box-shadow: 0px 0px 50px #5cb615;
}

.cloud-list{  
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}

.cloud .animated-background .ball{
  background-color: #5cb615;
}

body.cloud {
  background-color: #3c7521;
}

.cloud-list button {
  position: relative;
  transition: transform 0.3s ease, padding-left 0.3s ease;
  padding-left: 0;
}

.cloud .cloud-list button {
  display: flex;
  align-items: center;
  padding: 5px 30px;
}

.cloud-list button::after {
  content: "";
  display: inline-block;
  background-image: url('./assets/images/icono_cloud_span.png');
  background-repeat: no-repeat;
  background-size: 50px auto;
  width: 50px; 
  height: 50px; 
  font-family: 'Telefonica ExtraLight';
  font-weight: 100;
  position: absolute;
  left: -50px; 
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}

.cloud-list button:hover {
  transform: translateX(20px);
  padding-left: 60px;
}

.cloud-list button:hover::after {
  left: 10px; 
  opacity: 1;
}



/* IoT */
.iot .iot-list button {
  background-color: #cacaca00;
  color: #fff;
  display: block;
  font-family: Telefonica ExtraLight;
  font-size: 3em;
  border: 1px solid;
  padding: 5px 30px;
  margin: 10px 0;
  border-radius: 20px;
}

.iot .iot-list button:hover {
  color: #e63780;
  box-shadow: 0px 0px 50px #e63780;
}

.iot-list{  
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}

.iot .animated-background .ball{
  background-color: #e63780;
}

body.iot{
  background-color: #922859;
}

.iot-list button {
  position: relative;
  transition: transform 0.3s ease, padding-left 0.3s ease;
  padding-left: 0;
}

.iot .iot-list button {
  display: flex;
  align-items: center;
}

.iot-list button::after {
  content: "";
  display: inline-block;
  background-image: url('./assets/images/icono_iot_span.png');
  background-repeat: no-repeat;
  background-size: 50px auto;
  width: 50px; 
  height: 50px; 
  font-family: 'Telefonica ExtraLight';
  font-weight: 100;
  position: absolute;
  left: -50px; 
  opacity: 0;
  transition: opacity 0.3s ease, left 0.3s ease;
}

.iot-list button:hover {
  transform: translateX(20px);
  padding-left: 60px;
}

.iot-list button:hover::after {
  left: 10px; 
  opacity: 1;
}



/*--- Video ---*/
.video-section iframe{
  width: 35vw;
} 




/*--- Fondos paginas principales ---*/
.image-fondo-container img{
  width: 80%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  object-fit: contain;
  pointer-events: none;
}




/*--- Loader ---*/
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 92%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
}

.loader-content img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  animation: bounce 1s infinite, fadeInOut 1s infinite;
}





/*--- Slider Component ---*/
#slider_component .slider h1 {
  color: #575756;
  font-size: 3em !important;
  line-height: 1em;
}

#slider_component h3 {
  font-size: 1.5em;
}

#slider_component p {
  font-size: 1.2em;
  line-height: 1.2em;
}

#slider_component .active-button {
  border: 2px solid #ffffff;
  background-color: #575756;
  color: white;
}

#slider_component .slider {
  width: 100%;
  height: 100%;
  overflow: hidden; 
  position: relative;
}

#slider_component .slide {
  width: 60vw; 
  min-width: 60vw; 
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  margin-right: 5px; 
  transform: scale(0.8);
  opacity: 0.5; 
}

#slider_component .slide.active {
  transform: scale(1) !important;
  opacity: 1 !important;
}

#slider_component .slides {
  margin: 10vh 0;
  display: flex;
  transition: transform 0.5s ease;
  justify-content: flex-start;
}

#slider_component .containerslide {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#slider_component .containerslide .left {
  width: 50%;
  height: 100%;
  border-radius: 50px 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  color: #575756;
}

#slider_component .lefttop, #slider_component .righttop {
  width: 60%;
}

#slider_component .containerslide .right {
  height: 100%;
  border-radius: 0px 50px 50px 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

#slider_component .buttons {
  position: absolute;
  bottom: 30px;
  width: 100vw;
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#slider_component button {
  padding: 10px;
  margin: 10px;
  border: 2px solid #ffffff;
}




/*--- Paginas Productos---*/
.video-section {
  text-align: center;
}

section.video-section{
  margin-left: auto;
  width: 50%;
  display: revert;
  height: auto;
}

section.video-section p.floatvideop{
  float: left;
  left: -30%;
  top: 150px;
  width: 300px; 
  text-align: end;
  position: absolute;
  z-index: 9999;
}  

p.bottomvideop {
  text-align: right;
  padding-bottom: 20px;
  width: 30vw;
  float: right;
  font-size: 1.5em;
  font-family: 'Telefonica ExtraLight';
}

p.bottomvideop b {
  color: #4ee9fe;
}  

p.bottomvideop::after{
  content: "";
  display: block;
  float: right;
  margin-top: 10px;
  opacity: 1;
  transition: all .8s ease;
  right: 15vw;
  position: absolute;
  width: 20vw;
  z-index: 9999;
}

body.comunicacion p.bottomvideop::after {
  border-bottom: 1px solid #4ee9fe;
}

.video-section video {
  width: 100%;
  max-width: 40vw;
}

.benefits-section-text{
  margin: 15% 0 0 0;
}

.benefits-section{
  margin: 0 0 10% 0;
}

.benefitcontainer{
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
}

.benefits-section svg{
  opacity: 0.8;
}








/*--- Seccion Marcas Seguridad ---*/
.marcas-section{
  margin: 10% 0 15% 0;
}

.marcas-section .marcas img{
  max-width: 150px;
  margin: 20px 10px;
}

.marcascontainer{
  display: flex;
  margin: 5% 0;
  flex-wrap: wrap;
  justify-content: center;
}








/*--- Boton Movistar Link ---*/
.conocemasaca{
  min-width: 200px;
  padding: 12px;
  font-size: 20px;
  border-radius: 5px;
  color: #4ee9fe;
  float: left;
  background-color: #00283b;
  transition: background-color 0.3s, opacity 0.3s;
  cursor: pointer;
  opacity: 1;
  margin: 70px 0 0 0;
  border: 1px solid #4ee9fe;
}

.conocemasaca:hover{
  color: #00283b;
  background-color: #4ee9fe;
  border: 1px solid #4ee9fe;
}








/*--- Why Movistar ---*/
.why-movistar{
  position: relative;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10%;
  background: transparent;
  color: white;
  z-index: 0;
  text-align: center;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid transparent;
}

.why-movistar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  border: 1px solid transparent;
  background-size: 300% 300%;
  background: linear-gradient(135deg, #4ee9fe, #164874, #164874, #4ee9fe, #4ee9fe);
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
  z-index: -1;
  transition: all .8s ease;
  opacity: 0.8; 
  animation: gradientAnimation 10s linear infinite;
}

.why-movistar h2 {
  text-align: center;
  margin-bottom: 20px;
}



body.comunicacion .benefit:hover p::before {
  background: linear-gradient(135deg, #4ee9fe, #164874, #4ee9fe);
}


body.cloud .why-movistar::before {
  background: linear-gradient(135deg, #46d787, #2cee12, #145f0a, #3cf48c, #46d787);
}

body.cloud .benefit:hover p::before {
  background: linear-gradient(135deg, #46d787, #145f0a, #46d787);
}



body.seguridad .why-movistar::before {
  background: linear-gradient(135deg, #d68ae9, #ee12e7, #6f056b, #ee12e7, #d68ae9);
}

body.seguridad .benefit:hover p::before {
  background: linear-gradient(135deg, #bc48da, #ee12e7, #d68ae9);
}

body.seguridad .benefit::before {
  background: linear-gradient(135deg, #d68ae9, #ee12e7, #6f056b, #d10d89, #d68ae9);
}




body.iot .why-movistar::before {
  background: linear-gradient(135deg, #da48a4, #d10d89, #910e61, #d10d89, #da48a4);
}

body.iot .benefit:hover p::before {
  background: linear-gradient(135deg, #da48a4, #910e61, #da48a4);
}

body.iot .benefit::before {
  background: linear-gradient(135deg, #da48a4, #d10d89, #910e61, #d10d89, #da48a4);
}





/*--- Benefit ---*/
.benefit{
  position: relative;
  width: 200px;
  height: auto;
  padding: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: transparent;
  color: white;
  z-index: 0;
  text-align: center;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid transparent;
}

.benefit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  border: 1px solid transparent;
  background: linear-gradient(135deg, #4ee9fe, #164874, #164874, #70bafb, #4ee9fe);
  background-size: 300% 300%;
  mask: 
    linear-gradient(#fff 0 0) padding-box, 
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
  z-index: -1;
  opacity: 0; 
  transition: all .8s ease;
}

.benefit:hover::before {
  animation: gradientAnimation 10s linear infinite;
  opacity: 0.8;
}

.benefit:hover {
  box-shadow: 0px 30px 40px #00000070;
}

.benefit p::before {
  content: ""; 
  display: block; 
  width: 60%; 
  margin: 0 auto;
  height: 1px; 
  background: #ffffff; 
  margin-bottom: 10px;
  opacity: 1;
  transition: all .8s ease;
}

.benefit b {
  color: #4ee9fe;
}

.benefit:hover p::before {
  opacity: 1;
  width: 100%; 
  background: linear-gradient(135deg, #4ee9fe,#164874,#4ee9fe);
}

.benefit img{
  width: 100px;
  height: 100px;
  margin-right: 10px;
}




/*--- Reasons ---*/
.reasoncontainer{
  display: flex;
}

.reason {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  width: 200px;
}

.reason img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}






/*--- Marquee ---*/
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100vw;
  position: absolute;
  left: 0;
}

.no-video .marquee-container {
  top: 13vw;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee linear infinite;
  font-size: 15vw;
}

.marquee span {
  display: inline-block;
  padding-right: 0;
  font-family: "Telefonica Bold", sans-serif;
  fill: none;
  stroke-width: 1;
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent; 
  opacity: 0.2;
}

.show-mobile{
  display: none;
}

.hide-mobile{
  display: block;
}







/*--- Colores Seguridad  ---*/
body.seguridad h1 b, body.seguridad h1.blue, body.seguridad .preheader p, body.seguridad p.bottomvideop b{
  color: #debce4;
}
body.seguridad .marquee span{
  -webkit-text-stroke: 1px #debce4;
}
body.seguridad .preheader p::after {
  background: #debce4;
}

body.seguridad p.bottomvideop::after {
  border-bottom: 1px solid #debce4;
}
body.seguridad .benefit b {
  color: #debce4;
}

/*--- Colores Cloud  ---*/
body.cloud h1 b, body.cloud h1.blue, body.cloud .preheader p, body.cloud p.bottomvideop b{
  color: #bde4a4;
}
body.cloud .marquee span{
  -webkit-text-stroke: 1px #ffffff;
}
body.cloud .preheader p::after {
  background: #bde4a4;
}

body.cloud p.bottomvideop::after {
  border-bottom: 1px solid #bde4a4;
}
body.cloud .benefit b {
  color: #bde4a4;
}

body.cloud .benefit::before {
  background: linear-gradient(135deg, #46d787, #2cee12, #145f0a, #3cf48c, #46d787);
}







/*--- Colores IoT  ---*/
body.iot h1 b, body.iot h1.blue, body.iot .preheader p, body.iot p.bottomvideop b{
  color: #fbc7de;
}
body.iot .marquee span{
  -webkit-text-stroke: 1px #ffffff;
}
body.iot .preheader p::after {
  background: #fbc7de;
}

body.iot p.bottomvideop::after {
  border-bottom: 1px solid #fbc7de;
}
body.iot .benefit b {
  color: #fbc7de;
}








/*--- Footer ---*/
.footer-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: white;
  position: relative;
}

.footer-container .icons-container {
  display: flex;
  align-items: center;
}

.footer-container .icon{
  margin: 0 10px;
  font-size: 25px;
  color: white;
}

.footer-container .separator {
  flex: 1 1;
  height: 1px;
  background-color: white;
  margin: 0 50px;
}  

.footer-container .text-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40vw;
}

img.movistaricon{
  width: 21px;
  padding: 0px;
}







/*--- Pagina de registros ---*/
.movistar-link{
  background-color: #51cfff73;
}

.registros h1{
  text-align: center;
}

.registros input{
  height: 30px;
  border-radius: 5px;
  background-color: #cacaca00;
  border: 1px solid #ffffff;
  color: #ffffff;
  margin: 10px;
  padding: 5px 10px;
  font-size: 16px;
}

.registros input::placeholder{
  color: #ffffff79!important;
}

textarea::placeholder{
  color: #ffffff!important;
}


.registros .registrados ul{
  display: flex;
  flex-wrap: wrap;
}

.registrados{
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 10%;
}

.cajaregistrofecha{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #ffffff4a;
  padding: 1% 0;
}

.cajaregistrofecha p{
  margin-bottom: 0px;
}

.cajaregistrofecha h2{
  margin: 0px;
  text-align: left;
}

.registrofecha{
  width: 100%;
}

.registrofecha h2{
  font-size: 1.5em;
}

.contenedorbotonexcel{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.contenedorbotonexcel button{
  background-color: #14495d;
  border: 1px solid #ffffff7a;
  color: #ffffff;
  border-radius: 5px;
  margin: 20px 0;
  max-width: 280px;
}

.contenedorbotonexcel button:hover{
  background-color: #207999;
  border: 1px solid #207999;
}





.box-comunicacion, .box-cloud, .box-seguridad, .box-iot, .box-default{
  padding: 20px;
  border: 1px solid #cacaca;
  border-radius: 20px;
  list-style: none;
  margin: 20px;
  width: 300px;
}

.box-cloud{
  background-color: #2cee127d;
  border: 1px solid #2cee12;
}

.box-comunicacion{
  background-color: #4ee9fe76;
  border: 1px solid #4ee9fe;
}

.box-iot{
  background-color: #db29a08b;
  border: 1px solid #db29a0;
}

.box-seguridad{
  background-color: #bf23de80;
  border: 1px solid #bf23de;
}



.botonEntrar{
  min-width: 200px;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s;
  cursor: pointer;
  background-color: rgb(29, 99, 120);
}

button#aplicarbutton:hover{
  box-shadow: 0px 5px 30px #ffffff;
}


/*--- Formulario ---*/
.form-container {
  display: flex;
  flex-direction: column;
}







/*--- Font Face ---*/
@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('./assets/fonts/MyriadPro-Regular.woff2') format('woff2'),
       url('./assets/fonts/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Bold';
  src: url('./assets/fonts/Telefonica-Bold.woff2') format('woff2'),
       url('./assets/fonts/Telefonica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica ExtraLight';
  src: url('./assets/fonts/Telefonica-ExtraLight.woff2') format('woff2'),
       url('./assets/fonts/Telefonica-ExtraLight.woff') format('woff');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Light';
  src: url('./assets/fonts/Telefonica-Light.woff2') format('woff2'),
       url('./assets/fonts/Telefonica-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Regular';
  src: url('./assets/fonts/Telefonica-Regular.woff2') format('woff2'),
       url('./assets/fonts/Telefonica-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Telefonica Regular Italic';
  src: url('./assets/fonts/Telefonica-RegularItalic.woff2') format('woff2'),
       url('./assets/fonts/Telefonica-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}



/*--- Key Frames ---*/
@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}





/*--- Media Queries Macbook Pro ---*/
@media screen and (max-width: 1728px) {
  .benefits-section-text{
    margin: 20% 0 0 0;
  }
  p.bottomvideop {
    width: 600px;
  }  
  .iot .iot-list button, .comunicacion .comunicacion-list button, .cloud .cloud-list button, .seguridad .seguridad-list button  {
    font-size: 3em;
  }
  .home .homeright img{
    max-width: 700px;
  }
  .container{
    width: 80%;
    margin: 0 10%;
  }
  .video-section iframe{
    width: 40vw;
  } 
}



/*--- Media Queries Tablet Horizontal ---*/
@media screen and (max-width: 1280px) {
  .benefits-section-text {
    margin: 20% 0 0 0;
  }
  .contenedortextsubheader {
    width: 70vw;
  }
  .container {
    width: 90%;
    margin: 0px 5%;
  }
  .benefits-section {
    margin: 0 0 10% 0;
  }
  section.video-section {
    margin-left: auto;
    width: 100%;
  }
  section.video-section p.floatvideop {
    left: 0%;
  }  
  section.video-section {
    margin: 50px 0 0 0;
    width: 100%;
  }
  p.bottomvideop {
    padding-top: 20px;
  }   
  .reason {
    width: auto;
    max-width: 250px;
  }
  .benefit {
    height: 300px;
  }  
  .subheader {
    margin-bottom: 0%;
  }
  .home .homeright img {
    max-width: 600px;
  }  
  p.bottomvideop:after {
    right: 5vw;
  }
  .video-section iframe{
    width: 90vw;
  }
}


/*--- Media Queries Tablet Vertical ---*/
@media screen and (max-width: 834px) {
  .conocemasaca {
    margin: 20px 0 70px 0;
    position: relative;
    z-index: 9999;
  }
  .video-section iframe{
    width: 90vw;
    height: 45vh;
  }
  .subheader h1 {
    font-size: 5em;
    line-height: 1em;
  }
  .container {
    width: 90%;
    margin: 0 5%;
  }
  .contenedortextsubheader {
    width: 70vw;
  }
  h1, h1.blue {
    font-size: 2em;
  }
  .home .homeright img {
    max-width: 300px;
  }  
  .home {
    flex-direction: column;
  }  
  section.video-section {
    width: 100%;
  }
  section.video-section p.floatvideop {
    display: none;
  }
  section.video-section {
    margin: 50px 0px 0px 0px;
  }
  .benefit img {
    width: 80px;
    height: 80px;
  }  
  .benefit {
    width: 230px;
    height: 250px;
    padding: 2%;
    box-shadow: 0px 30px 40px #00000070;
  } 
  .why-movistar {
    margin-bottom: 30%; 
    box-shadow: 0px 30px 40px #00000070;
  }  
  .benefitcontainer {
    flex-wrap: wrap;
    gap: 60px;
  } 
  .benefit::before {
    animation: gradientAnimation 10s linear infinite;
    opacity: 0.8;
  }
  .home .homeleft {
    align-items: center;
    text-align: center;
  }
  h1, h1.blue {
    font-size: 4em;
  }
  #butt_comunicacion, #butt_seguridad, #butt_cloud, #butt_iot{
    opacity: 1!important;
  }
  .home .button-container {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    width: 70vw;
    justify-content: center;
    margin: 10%;
  }
  #butt_comunicacion img, #butt_seguridad img, #butt_cloud img, #butt_iot img {
    width: 200px;
    height: 200px;
  }
  #butt_comunicacion, #butt_seguridad, #butt_cloud, #butt_iot {
    width: 200px;
    height: 200px;
  }
  .cursor {
    display: none;
  }
  .benefit {
    justify-content: center;
  }  
  .benefits-section-text {
    margin: 30% 0 0 0;
  }
  .seguridad .seguridad-list button, .comunicacion .comunicacion-list button, .iot .iot-list button, .cloud .cloud-list button {
    font-size: 3em;
  }
}


@media (min-width: 768px) {
  .form-container {
      flex-direction: row;
      justify-content: space-between;
  }
  .form-group {
    width: 98%;
  }
  .form-group.sele {
      width: 48%;
  }
}


@media screen and (max-width: 768px) {
  .seguridad .seguridad-list button, .comunicacion .comunicacion-list button, .iot .iot-list button, .cloud .cloud-list button {
    font-size: 3em;
    border-radius: 10px;
  }
  .footer-container {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-container .text-container {
    width: 100vw;
    justify-content: center;
    margin-top: 20px;
  }
  img.evoluciondigital{
    max-width: 500px;
  }  
  #butt_comunicacion_mob img, #butt_seguridad_mob img, #butt_cloud_mob img, #butt_iot_mob img, #butt_contacto_mob img {
    border-radius: 15px;
  }
} 

@media screen and (max-width: 767px) {
  .show-mobile {
    display: block;
  }
}  


/*--- Media Queries Mobile ---*/
@media screen and (max-width: 440px) {
  body.comunicacion #butt_comunicacion_mob img{
    box-shadow: 0px 0px 20px #4ee9fe;
  }
  
  body.seguridad #butt_seguridad_mob img{
    box-shadow: 0px 0px 20px #be23de;
  }
  
  body.cloud #butt_cloud_mob img{
    box-shadow: 0px 0px 20px #3bde01;
  }
  
  body.iot #butt_iot_mob img{
    box-shadow: 0px 0px 20px #db299f;
  }
  
  body.contacto #butt_contacto_mob img{
    box-shadow: 0px 0px 20px #ffffff;
  }
  .form-group input{
    margin: 0px;
    display: flex;
    width: 300px !important;
  }
  textarea{
    width: 300px !important;
  }
  .home .button-container {
    gap: 20px;
    margin: 15% 5%;
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
  } 
  #butt_comunicacion img, #butt_seguridad img, #butt_cloud img, #butt_iot img, #butt_contacto img  {
    width: 80px;
    height: 80px;
  }
  #butt_comunicacion, #butt_seguridad, #butt_cloud, #butt_iot, #butt_contacto {
    width: 80px;
    height: 80px;
  }
  .hide-mobile{
    display: none;
  }
  .button-container {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10%;
  }
  img.evoluciondigital{
    max-width: 300px;
  } 
  .home .homeleft {
    align-items: center;
  }  
  .home h1 {
    text-align: center;
  } 
  .home .homeright img {
    max-width: 360px;
    opacity: 0.3;
    pointer-events: none;
    bottom: -50px;
  }
  .home .homeleft {
    padding: 0;
    margin-top: 2%;
  }
  .benefitcontainer {
    flex-direction: column;
  }  
  .reasoncontainer {
    flex-direction: column;
  }  
  .why-movistar {
    width: 90%;
    margin: 30% auto;
  } 
  .benefit:hover p::before {
    width: 80%;
  }  
  .preheader {
    flex-direction: column;
  }  
  .preheader p {
    width: 100%;
  }  
  .subheader h1 {
    font-size: 4em;
    margin-top: 20%;
  }
  p.bottomvideop {
    width: 300px;
    font-size: 1.2em;
    padding: 0px;
    margin-bottom: 20%;
  }
  .video-section iframe{
    display: flex;
    width: 90vw;
    height: 250px;
  }  
  .benefit {
    width: 80%;
    height: 300px;
    box-shadow: 0px 30px 40px #00000070;
    padding: 0 5%;
  }  
  .benefit::before {
    animation: gradientAnimation 10s linear infinite;
    opacity: 1;
  }
  .benefits-section-text {
    margin: 0;
  }
  #butt_comunicacion, #butt_seguridad, #butt_cloud, #butt_iot, #butt_contacto  {
    opacity: 1!important;
  }
  .iot .iot-list button, .seguridad .seguridad-list button, .comunicacion .comunicacion-list button, .cloud .cloud-list button  {
    font-size: 1.5em;
    white-space: nowrap;
    margin: 20px 0;
  } 
  .contenedortextsubheader {
    width: 80vw;
  }
  .benefits-section-text text{
    font-size: 220px;
  }
  .subheader {
    margin-bottom: 0%;
  }
  .benefitcontainer {
    margin-top: 30px;
  }  
  .marquee {
    font-size: 30vw;
  }
  .home, .home .homeleft {
    height: 70vh;
  }  
  .image-fondo-container img{
    width: 160%;
  }  
  .reason{
    padding: 20px 30px;
  }  
  .logo-container .logo{
    width: 140px;
  } 
  .comunicacion-list button::after, .seguridad-list button::after, .cloud-list button::after, .iot-list button::after {
    display: none;
    left: -20px; 
  }
  .comunicacion-list button:hover, .seguridad-list button:hover, .cloud-list button:hover, .iot-list button:hover  {
    transform: translateX(0px);
    padding-left: 0px;
  }
  .comunicacion-list button:hover::after, .seguridad-list button:hover::after, .cloud-list button:hover::after, .iot-list button:hover::after  {
    left: 5px; 
  }
  h1, h1.blue {
    font-size: 1.8em;
  }
}